import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import "./fonts/Gilroy-ExtraBold.otf";
import "./fonts/Gilroy-Light.otf";
import "./css/style.css";

const THEME = createTheme({
	typography: {
		fontFamily: "Lato",
	},
	palette: {
		primary: {
			main: "#aa1111",
		},
	},
});
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<ThemeProvider theme={THEME}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ThemeProvider>
);
