import React, { useEffect, useState } from "react";
import data from "../data/data.json";
import "../css/style.css";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Checkbox,
	ListItemText,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	Typography,
} from "@mui/material/";
import bottomLineLogoArtboard from "../images/bottom line logoArtboard 24.svg";
import clientsPageLandingLogo from "../images/clients_page_landing_logo.svg";
import clientsPageNotesIcon from "../images/clients_page_notes_icon.svg";
import almaEconomicsLogo from "../images/alma_economics_logo.svg";

function Home() {
	const sectors = ["Libraries", "Archives", "Museums", "Arts", "Heritage"];
	const topics = [
		"Users/visitors/attendants",
		"Workforce",
		"Organisations/sector",
	];
	const subTopics = {
		"Users/visitors/attendants": [
			"Number/characteristics",
			"Frequency",
			"Experience/satisfaction",
			"Motivations",
			"Barriers/engagement",
			"Information sources",
		],
		Workforce: [
			"Number/characteristics",
			"Earnings",
			"Qualifications/skills",
		],
		"Organisations/sector": [
			"Number/characteristics",
			"Offer/performance",
			"Digital",
			"Income/turnover",
			"Expenditure/investment",
			"GVA/economic impact",
		],
	};

	const [isCheckAllSectors, setIsCheckAllSectors] = useState(true);
	const [sectorsSelected, setSectorsSelected] = useState(sectors);
	const [topicSelected, setTopicSelected] = useState(
		"Users/visitors/attendants"
	);
	const [subTopicsToShow, setSubTopicsToShow] = useState(
		subTopics["Users/visitors/attendants"]
	);
	const [subTopicsSelected, setSubTopicsSelected] = useState(
		subTopics["Users/visitors/attendants"]
	);
	const [isCheckAllSubTopics, setIsCheckAllSubTopics] = useState(true);

	const [arrayData, setArrayData] = useState();

	useEffect(() => {
		setIsCheckAllSubTopics(true);
		setSubTopicsToShow(subTopics[topicSelected]);
		setSubTopicsSelected(subTopics[topicSelected]);
		// eslint-disable-next-line
	}, [topicSelected]);

	useEffect(() => {
		const dataToShow = data.filter(
			(element) =>
				sectorsSelected.includes(element["Sector"]) &&
				topicSelected === element["Topic"] &&
				subTopicsSelected.includes(element["Sub-topic"])
		);
		document.getElementsByClassName("MuiTableContainer-root")[0] &&
			(document.getElementsByClassName(
				"MuiTableContainer-root"
			)[0].scrollTop = 0);
		setArrayData(
			dataToShow.map((element) => [
				element["Indicator"],
				element["Description"],
				element["Source"],
				element["Year of the indicator"],
				element["Link"],
			])
		);
	}, [topicSelected, subTopicsSelected, sectorsSelected]);

	useEffect(() => {
		if (arrayData) {
			const uniqueSubarrays = new Map();
			for (const subarray of arrayData) {
				uniqueSubarrays.set(JSON.stringify(subarray), subarray);
			}
			if (
				Array.from(uniqueSubarrays.values()).length !== arrayData.length
			) {
				setArrayData(Array.from(uniqueSubarrays.values()));
			}
		}
	}, [arrayData]);

	const handleChangeMulti = (
		e,
		initial,
		setSelected,
		isCheckAll,
		setIsCheckAll
	) => {
		e.preventDefault();
		if (e.target.value.includes("Select All")) {
			if (isCheckAll) {
				setSelected([]);
			} else {
				setSelected(initial);
			}
			setIsCheckAll(!isCheckAll);
		} else {
			setIsCheckAll(false);
			setSelected(e.target.value);
		}
	};

	const handleChangeSingle = (e, setValue) => {
		e.preventDefault();
		setValue(e.target.value);
	};

	return (
		<div className="app-container">
			<Typography variant="h6" className="filters-description">
				Use the filters below to narrow down and discover a curated list
				of data sources related to the culture sector in Wales:
			</Typography>

			<div className="filters-container">
				<FormControl
					className="filter"
					id="sectors-filter-container"
					size="small"
					variant="standard"
				>
					<InputLabel id="sectors-filter-label">Sector</InputLabel>
					<Select
						labelId="filter-multiple-checkbox-label"
						id="filter-multiple-checkbox"
						multiple
						value={sectorsSelected}
						onChange={(event) =>
							handleChangeMulti(
								event,
								sectors,
								setSectorsSelected,
								isCheckAllSectors,
								setIsCheckAllSectors
							)
						}
						label="Sector"
						renderValue={(selected) => {
							selected = selected.filter((n) => n);
							return selected.join(", ");
						}}
					>
						<MenuItem key="Select All" value="Select All">
							<Checkbox checked={isCheckAllSectors} />
							<ListItemText primary="Select All" />
						</MenuItem>
						{sectors.map((sector) => (
							<MenuItem key={sector} value={sector}>
								<Checkbox
									checked={
										sectorsSelected.indexOf(sector) > -1
									}
								/>
								<ListItemText primary={sector} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl
					className="filter"
					id="topics-filter-container"
					size="small"
					variant="standard"
				>
					<InputLabel id="topics-filter-label">Topic</InputLabel>
					<Select
						labelId="filter-columns-multiple-checkbox-label"
						id="filter-columns-multiple-checkbox"
						value={topicSelected}
						onChange={(event) =>
							handleChangeSingle(event, setTopicSelected)
						}
						label="Topic"
					>
						{topics.map((topic) => (
							<MenuItem key={topic} value={topic}>
								{topic}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl
					className="filter"
					id="sub-topics-filter-container"
					size="small"
					variant="standard"
				>
					<InputLabel id="sub-topics-filter-label">
						Sub-topic
					</InputLabel>
					<Select
						labelId="filter-multiple-checkbox-label"
						id="filter-multiple-checkbox"
						multiple
						value={subTopicsSelected}
						onChange={(event) =>
							handleChangeMulti(
								event,
								subTopicsToShow,
								setSubTopicsSelected,
								isCheckAllSubTopics,
								setIsCheckAllSubTopics
							)
						}
						renderValue={(selected) => {
							selected = selected.filter((n) => n);
							return selected.join(", ");
						}}
						label="Sub-topics"
					>
						<MenuItem key="Select All" value="Select All">
							<Checkbox checked={isCheckAllSubTopics} />
							<ListItemText primary="Select All" />
						</MenuItem>
						{subTopicsToShow.map((subTopic) => (
							<MenuItem key={subTopic} value={subTopic}>
								<Checkbox
									checked={
										subTopicsSelected.indexOf(subTopic) > -1
									}
								/>
								<ListItemText primary={subTopic} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			{arrayData && (
				<TableContainer className="table-container">
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								{[
									"Indicator",
									"Description",
									"Source",
									"Year",
									"Link",
								].map((header) => (
									<TableCell key={"header-" + header}>
										{header}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody className="table-body">
							{arrayData.length ? (
								arrayData.map((row, index) => (
									<TableRow key={"row-" + index}>
										{row.map((cell, index) => {
											if (index !== 4) {
												return (
													<TableCell
														key={"cell-" + index}
													>
														{cell}
													</TableCell>
												);
											} else {
												return (
													<TableCell
														key={"cell-" + index}
													>
														<a
															href={cell}
															target="_blank"
															rel="noopener noreferrer"
															className="paper-link"
														>
															View source
														</a>
													</TableCell>
												);
											}
										})}
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={6} align="center">
										no results
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<div id="bottom">
				<div className="row" style={{ marginLeft: 0 }}>
					<div id="logo" style={{ marginRight: 10 }}>
						<a
							href="https://evidencemap.com/"
							id="copyright-link"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								id="em"
								src={clientsPageLandingLogo}
								alt="Evidence Map logo"
								className="img-fluid"
								style={{ width: 150, marginBottom: "2rem" }}
							/>
						</a>
					</div>
					<div
						id="line-wide"
						style={{
							marginTop: "0.7rem",
							width: "calc(100% - 510px)",
						}}
					>
						<hr
							style={{
								height: 1,
								backgroundColor: "#d3d3d3",
								borderTop: "none",
								marginTop: "1.3rem",
							}}
						/>
					</div>
					<div id="alma-logo-wide" style={{ marginLeft: 10 }}>
						<a
							href="https://www.almaeconomics.com/"
							id="copyright-link"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								id="alma"
								src={bottomLineLogoArtboard}
								alt="Alma Economics logo"
								className="img-fluid"
								style={{ height: 35, marginBottom: "-1rem" }}
							/>
						</a>
					</div>
					<div
						id="line"
						style={{
							marginTop: "0.7rem",
							width: "calc(100% - 335px)",
						}}
					>
						<hr
							style={{
								height: 1,
								backgroundColor: "#d3d3d3",
								borderTop: "none",
								marginTop: "1.3rem",
							}}
						/>
					</div>
					<div id="alma-logo" style={{ marginLeft: 10 }}>
						<a
							href="https://www.almaeconomics.com/"
							id="copyright-link"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								id="alma"
								src={almaEconomicsLogo}
								alt="Alma Economics logo"
								className="img-fluid"
								style={{ height: 35, marginBottom: "-1.2rem" }}
							/>
						</a>
					</div>
				</div>
				<a
					className="about-container"
					href="/about"
					style={{ cursor: "pointer", width: "260px" }}
				>
					<div className="notes-icon">
						<img src={clientsPageNotesIcon} alt="Report" />
					</div>
					<div className="about-text text">Notes on methodology</div>
				</a>
			</div>
		</div>
	);
}

export default Home;
