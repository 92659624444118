import { Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import Home from "./Home.jsx";
import About from "./About.jsx";
import icon from "../images/favicon.ico";

function App() {
	useEffect(() => {
		const favicon = document.getElementById("favicon");
		favicon.setAttribute("href", icon);
	}, []);

	return (
		<div>
			<Header />
			<Routes>
				<Route path="*" element={<Home />} />
				<Route path="/about" element={<About />} />
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
