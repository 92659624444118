import React from "react";
import "../css/footer.css";
import EvidenceMapLogo from "../images/evidence_map_logo_white.svg";
import AlmaEconomicsLogoWhite from "../images/alma_economics_logo_white.svg";

function Footer() {
	return (
		<>
			<div
				className="footer-container"
				id="desktop-footer"
				style={{ marginTop: "170px" }}
			>
				<div className="container footer-container-wrapper">
					<div className="footer-container-left-section">
						<a
							href="https://evidencemap.com/"
							id="copyright-link"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={EvidenceMapLogo}
								alt="Evidence Map logo"
								style={{ cursor: "pointer" }}
							/>
						</a>
					</div>
					<div className="footer-container-middle-section">
						<span
							style={{
								lineHeight: "10px",
							}}
						>
							{" "}
							Copyright © 2023 All rights reserved <br />
							Evidence Map is an Alma Economics product <br />
						</span>
						<span style={{ lineHeight: "25px" }}>
							{" "}
							<span
								style={{
									fontFamily: '"Gilroy ExtraBold"',
								}}
							>
								Let's connect:
							</span>
							<br />
							<a
								href="mailto:evidencemap@almaeconomics.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								Email
							</a>
							<a
								href="https://www.almaeconomics.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								Website
							</a>
							<a
								href="https://www.linkedin.com/company/alma-economics/"
								target="_blank"
								rel="noopener noreferrer"
							>
								LinkedIn
							</a>
							<a
								href="https://twitter.com/almaeconomics"
								target="_blank"
								rel="noopener noreferrer"
							>
								Twitter
							</a>
							<a
								href="https://www.facebook.com/people/Alma-Economics/100083371470933/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Facebook
							</a>
							<a
								href="https://www.instagram.com/alma_economics/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Instagram
							</a>
							<br />
						</span>
					</div>
					<div className="footer-container-right-section">
						<a
							href="https://www.almaeconomics.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={AlmaEconomicsLogoWhite}
								alt="Alma Economics Logo"
							/>
						</a>
					</div>
				</div>
			</div>
			<div
				className="footer-container"
				style={{ padding: "30px 15px" }}
				id="mobile-footer"
			>
				<div className="container footer-container-logos-section">
					<a
						href="https://evidencemap.com/"
						id="copyright-link"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							id="footer-evidence-map-logo"
							src={EvidenceMapLogo}
							alt="Evidence Map logo"
							style={{ cursor: "pointer" }}
						/>
					</a>
					<a
						id="footer-alma-economics-logo"
						href="https://www.almaeconomics.com/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src={AlmaEconomicsLogoWhite}
							alt="Alma Economics Logo"
						/>
					</a>
				</div>
				<div className="container footer-container-text-section">
					<span style={{ lineHeight: "10px" }}>
						{" "}
						Copyright © 2023 All rights reserved <br />
						Evidence Map is an Alma Economics product <br />
					</span>
					<span style={{ lineHeight: "25px" }}>
						{" "}
						<span
							style={{
								fontFamily: '"Gilroy ExtraBold"',
							}}
						>
							Let's connect:
						</span>
						<br />
						<a
							href="mailto:evidencemap@almaeconomics.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							Email
						</a>
						<a
							href="https://www.almaeconomics.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							Website
						</a>
						<a
							href="https://www.linkedin.com/company/alma-economics/"
							target="_blank"
							rel="noopener noreferrer"
						>
							LinkedIn
						</a>
						<a
							href="https://twitter.com/almaeconomics"
							target="_blank"
							rel="noopener noreferrer"
						>
							Twitter
						</a>
						<a
							href="https://www.facebook.com/people/Alma-Economics/100083371470933/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Facebook
						</a>
						<a
							href="https://www.instagram.com/alma_economics/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Instagram
						</a>
						<br />
					</span>
				</div>
			</div>
		</>
	);
}

export default Footer;
