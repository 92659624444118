import React from "react";

function About() {
	return (
		<div className="container" id="about">
			<div className="row mt-4">
				<div className="col-12">
					<p>
						Alma Economics has been commissioned by the Welsh
						Government to conduct a review of the Welsh culture
						sector evidence base across key cultural areas of
						interest, which include museums, the arts, libraries,
						archives and heritage. This review was required to
						ensure that the Welsh Government are accessing,
						harnessing, and using the best available data and
						research evidence for effective policy making and to
						inform future research priorities. For this purpose, we
						developed a map summarising the publicly available data
						and evidence on the five culture sub-sector.
					</p>
					<p>
						After carefully analysing the sources of data and
						evidence identified through our systematic search
						strategy, we mapped each indicator, or set of similar
						indicators, included in these sources against three main
						topics of interest - namely, (i) users / visitors /
						attendants, (ii) workforce and (iii) organisations - and
						a series of specific subtopics. The evidence map allows
						to seamlessly navigate through these indicators across
						the five culture sub-sectors as well as by topic and
						subtopic.
					</p>
					<p>
						For each indicator, the evidence map provides a (i)
						short description, (ii) the source, (iii) the year for
						which the indicator is last available, and (iv) a link
						to the source. The map is interactive, including filters
						that allow the users to swiftly explore the available
						evidence across sub-sectors, topics and subtopics.
					</p>
				</div>
			</div>
			<a
				href="/"
				className="btn btn-primary col-12 col-sm-12 col-md-4 mt-4"
				id="back_to_map_button"
			>
				Back
			</a>
		</div>
	);
}

export default About;
